@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Klee+One&family=League+Spartan:wght@100..900&family=Noto+Sans+JP:wght@100..900&family=Quintessential&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Klee+One&family=League+Spartan:wght@100..900&family=Noto+Sans+JP:wght@100..900&family=Quintessential&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

.App {
    text-align: center;
    max-width: 40rem;
    font-size: 1rem;
    margin: 0 auto;
    font-family: 'Noto Sans JP';
    }

.App-header {
    flex-direction: column;
    align-items: center;
    position: sticky; 
    bottom: 0;
    /* width: 100%; */
    height: 50vh;
    /* animation: image_anime 10s ease infinite; */
}

h4 {
    font-family: 'Libre Baskerville';
    position: relative; /* 相対指定 */
}

.nolSubMenu{
    font-size: 2rem;
    margin-top: 4rem;
}


/* ヘッダー */
.nolHeader {
    /* background-color: #fdf5e6; nolイメージカラー */
    /* display: flex; */
    width: 100%;
    /* font-family: 'Josefin Sans';
    color: #fff;
    justify-content: center;
    align-items: center;
    line-height: 8vh;
    margin: auto; */
}

.topImage {
    width: 100%;
    height: 20rem;
    object-fit: cover;
    z-index: -1;
}

.headerTitle {
    letter-spacing: 0.1em;
    font-size: 2rem;
}

.nolKana{
    font-size: 1rem;
}


/* メイン */
.nolMain {
    /* background-color: #f5f5f5; */
    margin-left: 1rem;
    margin-right: 1rem;
}

.nolConcept{
    margin: auto;
}

.massage {
    margin: 1rem;
}

.map {
    margin: 2rem;
}

table {
    border-collapse: separate;
    border-spacing: 0;
    width: 80%;
    font-size: 0.75rem;
    /* background-color: var(--color-white); */
    margin: auto;
}
th,td {
    border-bottom: solid 0.25px #444;
    padding: 5px 0;
    line-height: 1.5;
}

.App-link {
    color: #61dafb;
}

.mainImage{
    width: 80%;
    height: 3rem;
    z-index: -1;
}

.fade-element {
    opacity: 0;
}

.fade-element.visible {
    animation-name: FadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    opacity: 0;
}

.aboutImage {
    width: 70%;
    height: 15rem;
    object-fit: cover;
    z-index: -1;
}

.nol-footer {
    background-color: #f5f5f5;
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 8vh;
    margin: auto;
}
  
  
@keyframes FadeIn {
0% {
    opacity: 0;
    transform: translateY(150px);
}

100% {
    opacity: 1;
    transform: translateY(0);
}
}

/* @keyframes image_anime {
    0% {
      background-image: url(img/teaser5.jpg);
      background-size: cover;
    }
    50% {
      background-image: url(img/teaser7.jpg);
      background-size: cover;
    }
    100% {
      background-image: url(img/teaser5.jpg);
      background-size: cover;
    }
  } */
